<!--
 * @Description: 企业找回账号-企业审核
 * @Version:
 * @Autor: houchuanfang
 * @Date: 2022-03-16 14:18:32
 * @LastEditTime: 2022-03-25 15:11:56
-->
<template>
  <div class="retrieve">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 信息审核</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="tab">
        <h1>通过以下流程找回账号密码</h1>
        <ul class="ul">
          <li class="active">
            <img src="~/static/login/01_active.png" alt="">
            <h3>提供资料</h3>
          </li>
          <li>
            <img src="~/static/login/02.png" alt="">
            <h3>信息审核</h3>
          </li>
          <li>
            <img src="~/static/login/03.png" alt="">
            <h3>找回账号密码</h3>
          </li>
        </ul>
      </div>
      <p class="tips">请您提供以下资料，找回 {{ $route.query.name }} 的账号密码</p>
      <div class="form">
        <el-form ref="form" label-width="110px">
          <el-form-item label="营业执照照片">
            <div class="rt">
              <el-popover placement="right" width="400" trigger="hover">
                <div class="des-popover">
                  <img src="@/static/enterprise/business_license.png" alt="">
                  <ul>
                    <li>注意事项</li>
                    <li>1、企业名称与企业证照信息必须一致；</li>
                    <li>2、营业执照有效期大于15天；</li>
                    <li>3、营业执照政府章与工商局登记机关信息一致；</li>
                    <li>4、复印版证照需加盖企业红色公章，签章需清晰可见；</li>
                    <li>5、证件完整、清晰、有效、不能为电子版或截屏。</li>
                  </ul>
                </div>
                <span class="text" slot="reference">查看示例</span>
              </el-popover>
              <el-popover placement="top-start" width="400" trigger="hover">
                <div class="des-popover">
                  <img :src="image.img1" alt="">
                </div>
                <span class="text" slot="reference" v-show="image.img1">已上传</span>
              </el-popover>
              <template>
                <el-upload class="upload" action="/api/app-jycy-disabled/uploadImg" :headers="uploadHeaders" :limit="1" :show-file-list='false'
                           :on-success="(file,fileList) =>{jobSucces(file,fileList,'1')}">
                  <span class="text">{{ image.img1 ? '重新上传' : '上传' }}</span>
                </el-upload>
              </template>
            </div>
          </el-form-item>
          <el-form-item label="在职证明照片">
            <div class="rt">
              <a href="https://imgcdpee.cdpee.org.cn/empcerttmplation.doc" target="_blank" download="在职证明模板" v-if="!image.img2"
                 class="text">下载模板</a>
              <el-popover placement="right" width="400" trigger="hover">
                <div class="des-popover">
                  <img src="@/static/enterprise/setting/people.jpg" alt="">
                  <ul>
                    <li>注意事项</li>
                    <li>1、需填写被委托员工列表；</li>
                    <li>2、单位名称、日期要填写完整；</li>
                    <li>3、加盖红色公章或人事章；</li>
                  </ul>
                </div>
                <span class="text" slot="reference">查看示例</span>
              </el-popover>
              <el-popover placement="top-start" width="400" trigger="hover">
                <div class="des-popover">
                  <img :src="image.img2" alt="">
                </div>
                <span class="text" slot="reference" v-show="image.img2">已上传</span>
              </el-popover>
              <template>
                <el-upload class="upload" action="/api/app-jycy-disabled/uploadImg" :headers="uploadHeaders" :limit="1" :show-file-list='false'
                           :on-success="(file,fileList) =>{jobSucces(file,fileList,'2')}">
                  <span class="text">{{ image.img2 ? '重新上传' : '上传' }}</span>
                </el-upload>
              </template>
            </div>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" class="w336" @click="goRouter(active)">{{ active == 1 ? '准备好了' : '提交' }}
          </el-button>
        </div>
        <p class="w336 text-tips">
          提示：如您暂未准备好上述资料，可将资料备好后，登录当前账号上传即可。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      url: '/retrievePassword/phone',
      active: 1,
      image: {
        img1: '',
        img2: '',
      }
    };
  },
  watch: {
    // $route: {
    //     handler(route) {
    //         const { path, name } = this.$route;
    //         this.pathName = name;
    //     },
    //     immediate: true,
    // },
  },
  created() {
  },
  methods: {
    handChange(val) {
      if (val == 1) {
        this.url = '/retrievePassword/phone';
      } else if (val == 2) {
        this.url = '/retrievePassword/email';
      } else {
        this.url = '/retrievePassword/info';
      }
    },
    goRouter(num) {
      if (this.active === 1) {
        this.active += num;
      } else if (this.active === 2) {
        this.$api.getSubmitImgUrlApi({
          compName: this.$route.query.name,
          licenseurlFileUrl: this.image.img1,
          reasonurlFileUrl: this.image.img2
        }).then(res => {
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.$router.push('/companyAudit/step2');
          } else {
            this.$message.error(res.data.msg);
          }
        })
      }
    },

    jobSucces(file, fileList, type) {
      if (type == 1) {
        this.image.img1 = file.msg;
      } else {
        this.image.img2 = file.msg;
      }
    },
  },
};
</script>

<style scoped lang="less">
.des-popover {
  img {
    display: block;
    height: 150px;
    margin: 0 auto;
    background: #ececec;
  }

  ul {
    margin-top: 10px;

    li {
      line-height: 25px;
    }
  }
}

.retrieve {
  width: 100%;
  min-height: calc(100vh - 269px);
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28a46d;
      }

      a {
        font-size: 18px;
        color: #66716c;
      }

      a:hover {
        color: #28a46d;
      }
    }

    .tab {
      width: 635px;
      margin: 0 auto;
      padding-top: 60px;

      h1 {
        font-size: 28px;
        padding-bottom: 36px;
        text-align: center;
      }

      .ul {
        display: flex;

        li {
          position: relative;
          flex: 1;
          text-align: center;

          h3 {
            font-size: 18px;
            margin-top: 6px;
            color: #66716c;
          }
        }

        li.active h3 {
          color: #28a46d;
        }

        li::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -50px;
          transform: translateY(-50%);
          width: 102px;
          height: 2px;
          background: #ccd0ce;
        }

        li:last-child::after {
          display: none;
        }
      }
    }
  }

  .tips {
    color: #000;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    margin: 30px 0;
  }

  .text-tips {
    font-size: 14px;
    color: #66716c;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .form {
    width: 510px;
    margin: 0 auto;
    border: 1px solid #dcdfe6;
    border-radius: 10px;

    /deep/ .el-form {
      margin-top: 20px;

      .el-form-item {
        width: 80%;
        margin: 0 auto 20px;
        background: rgba(0, 18, 10, 0.03);
        border-radius: 4px;

        .el-form-item__label {
          font-weight: 600;
          color: #000;
        }
      }
    }

    .rt {
      color: #28a46d;
      text-align: right;

      .text {
        margin-right: 20px;
        cursor: pointer;
      }

      a {
        color: #28a46d;
      }

      .upload {
        display: inline-block;
      }
    }

    .btn {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
</style>
